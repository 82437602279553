import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "nav-container" }
const _hoisted_2 = { class: "nav nav-tabs primary-tabs" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav nav-tabs primary-tabs right-tabs" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = {
  key: 0,
  class: "nav-item dropdown"
}
const _hoisted_11 = { class: "dropdown-menu" }

import { onMounted } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const storeIndividuConnecte = useIndividuConnecteStore()


const deconnexion = async () => {
  try {
    await storeIndividuConnecte.deconnexion();
    await router.push({ path: '/connexion' });
  } catch (error: unknown) {
    console.log("impossible de se déconnecter");
  }
}

const recupereInfoConnexion = async () => {
  await storeIndividuConnecte.connecterIndividu();
};

// Vérification de la connexion au chargement de l'application
onMounted(() =>
  recupereInfoConnexion()
)


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, {
            class: "nav-link",
            to: "/",
            href: "/"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("i", { class: "fa-solid fa-house" }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, {
            class: "nav-link",
            to: "/recherche-oeuvre/"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Recherche")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_5, [
          _createVNode(_component_router_link, {
            class: "nav-link",
            to: "/recherche-atelier/"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Ateliers d'écriture")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_6, [
          _createVNode(_component_router_link, {
            class: "nav-link",
            to: "/recherche-auteur/"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Auteurs")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_7, [
          _createVNode(_component_router_link, {
            class: "nav-link",
            to: "/recherche-discussion/"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Discussions")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("ul", _hoisted_8, [
        _createElementVNode("li", _hoisted_9, [
          _createVNode(_component_router_link, {
            class: "nav-link",
            to: "/confidentialite/"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Confidentialité")
            ])),
            _: 1
          })
        ]),
        (_unref(storeIndividuConnecte).individuConnecte?.id)
          ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
              _cache[10] || (_cache[10] = _createElementVNode("a", {
                class: "nav-link dropdown-toggle",
                "data-bs-toggle": "dropdown",
                href: "#",
                role: "button",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }, [
                _createElementVNode("i", { class: "fa-solid fa-gear" })
              ], -1)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_router_link, {
                  class: "dropdown-item",
                  to: '/individu/configuration/donnee-publique/'
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Données publiques ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  class: "dropdown-item",
                  to: '/individu/configuration/donnee-prive/'
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Données privées ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  class: "dropdown-item",
                  to: '/individu/configuration/abonnement/'
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" Abonnement ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  class: "dropdown-item",
                  to: '/individu/configuration/donnee-prive/'
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" Groupes ")
                  ])),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(storeIndividuConnecte).individuConnecte?.id !=null)
          ? (_openBlock(), _createElementBlock("li", {
              key: 1,
              class: "nav-item",
              onClick: _withModifiers(deconnexion, ["prevent"])
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("div", { class: "nav-link" }, [
                _createElementVNode("i", { class: "fa-solid fa-plug-circle-xmark fa-2" })
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}
}

})