<script setup lang="ts">

</script>

<template>
<div></div>
</template>

<style scoped lang="scss">

</style>