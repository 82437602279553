import { defineStore } from "pinia";
import axiosInstance from "@/utils/axios-config";
import { Erreur } from "@/utils/Erreur";
import { computed, ref } from "vue";

export class IndividuPresentation {
  id: number;
  pseudonyme: string;
  instantCreation: Date;
  actif: boolean;
  presentation: string;
  imagePresentation;

  constructor(id: number, pseudonyme: string, instantCreation: Date, actif: boolean, presentation: string, imagePresentation: string) {
    this.id = id;
    this.pseudonyme = pseudonyme;
    this.instantCreation = instantCreation;
    this.actif = actif;
    this.presentation = presentation;
    this.imagePresentation = imagePresentation;
  }

}

export const useIndividuPresentationStore = defineStore('individuPresentation', () => {
  const map = ref(new Map<number, IndividuPresentation>());
  const erreurSelection = ref<Erreur | null>(null);
  const erreurModification = ref<Erreur | null>(null);
  const individuSelectionne = ref<IndividuPresentation | null>(null);

  async function rechercherById(idIndividu: number) {
    if (!map.value.has(idIndividu)) {
      try {
        const response = await axiosInstance.get(`/private/individu/${idIndividu}`);
        map.value.set(idIndividu, response.data);
      } catch (error) {
        erreurSelection.value = new Erreur("Erreur lors de la récupération de l'individu")
      }
    }
    return map.value.get(idIndividu) || null;
  }

  async function selectionnerIndividu(idIndividu: number) {
    individuSelectionne.value = await rechercherById(idIndividu)
  }

  async function modifier(pseudonyme: string, textePresentation: string, imagePresentation: string) {
    if (!individuSelectionne.value) {
      erreurModification.value = erreurSelection.value;
      return;
    }
    if (pseudonyme.length == 0 || pseudonyme.length > 30) {
      erreurModification.value = new Erreur("Le pseudonyme ne doit pas dépasser 30 caractères")
      return
    }

    if (textePresentation && textePresentation.length > 2000) {
      erreurModification.value = new Erreur("Le texte de présentation ne doit pas dépasser 500 caractères, mise en forme comprise")
    }
    individuSelectionne.value.imagePresentation = imagePresentation
    individuSelectionne.value.pseudonyme = pseudonyme
    individuSelectionne.value.presentation = textePresentation
    if (individuSelectionne.value && !erreurModification.value) {
      try {
        await axiosInstance.put('/private/individu/', individuSelectionne.value);
        erreurSelection.value = null;
      } catch (error: unknown) {
        if (error != null) {
          erreurModification.value = new Erreur(error)
        }
      }
    }
    return;
  }

  const estErreurSelection = computed(() => {
    return erreurSelection.value !=null
  })


  return {
    selectionnerIndividu,
    estErreurSelection,
    individuSelectionne,
    erreurModification,
    erreurSelection,
    modifier
  }
});