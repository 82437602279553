<template>
  <div class="nav-container">
    <ul class="nav nav-tabs primary-tabs">
      <li class="nav-item">
        <router-link class="nav-link" to="/" href="/"><i class='fa-solid fa-house'></i></router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/recherche-oeuvre/">Recherche</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/recherche-atelier/">Ateliers d'écriture</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/recherche-auteur/">Auteurs</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/recherche-discussion/">Discussions</router-link>
      </li>

    </ul>

    <ul class="nav nav-tabs primary-tabs right-tabs">
      <li class="nav-item">
        <router-link class="nav-link" to="/confidentialite/">Confidentialité</router-link>
      </li>
      <li class="nav-item dropdown" v-if="storeIndividuConnecte.individuConnecte?.id">
        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true"
           aria-expanded="false">
          <i class="fa-solid fa-gear"></i>
        </a>
        <div class="dropdown-menu">
          <router-link class="dropdown-item" :to="'/individu/configuration/donnee-publique/'">
            Données publiques
          </router-link>
          <router-link class="dropdown-item" :to="'/individu/configuration/donnee-prive/'">
            Données privées
          </router-link>
          <router-link class="dropdown-item" :to="'/individu/configuration/abonnement/'">
            Abonnement
          </router-link>
          <router-link class="dropdown-item" :to="'/individu/configuration/donnee-prive/'">
            Groupes
          </router-link>
        </div>

      </li>
      <li class="nav-item" v-if="storeIndividuConnecte.individuConnecte?.id !=null" @click.prevent="deconnexion">
        <div class="nav-link">
          <i class='fa-solid fa-plug-circle-xmark fa-2'></i>
        </div>
      </li>

    </ul>
  </div>
  <router-view />
</template>




<script setup lang="ts">
import { onMounted } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import router from "@/router";

const storeIndividuConnecte = useIndividuConnecteStore()


const deconnexion = async () => {
  try {
    await storeIndividuConnecte.deconnexion();
    await router.push({ path: '/connexion' });
  } catch (error: unknown) {
    console.log("impossible de se déconnecter");
  }
}

const recupereInfoConnexion = async () => {
  await storeIndividuConnecte.connecterIndividu();
};

// Vérification de la connexion au chargement de l'application
onMounted(() =>
  recupereInfoConnexion()
)

</script>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$primary-color: $primary;
$primary-active-color: darken($primary, 10%);
$primary-hover-color: lighten($primary, 5%);

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  background-color: $primary-color
}

.primary-tabs {
  display: flex;
  background-color: $primary-color;

  .nav-item, .nav-item > .nav-link {
    background-color: $primary-color;
    color: white;
    border: 1px solid $primary-color;

    &:hover {
      background-color: $primary-hover-color;
      color: white;
    }

    &.active {
      background-color: $primary-active-color;
      color: white;
      border-bottom-color: transparent;
    }
  }
}

.right-tabs {
  display: flex;
  margin-left: auto;
}


.ql-font-monospace {
  font-family: var(--bs-font-monospace);
}
.ql-size-small{
  font-size: calc(var(--bs-body-font-size)*0.75);
}
.ql-size-large{
  font-size: calc(var(--bs-body-font-size)*1.5);
}
.ql-size-huge{
  font-size: calc(var(--bs-body-font-size)*2);
}
.ql-editor *{
  font-size: var(--bs-body-font-size);
}
.ql-font-serif{
  font-family: 'Garamond' !important;
}
.ql-font-gothique{
  font-family: 'Mirage Gothic' !important;
}
</style>
