import { defineStore } from "pinia";
import axiosInstance from "@/utils/axios-config";
import { Erreur } from "@/utils/Erreur";
import {  ref } from "vue";

export class IndividuDonneePrive {
  id: number;
  dateNaissance: Date;
  actif: boolean;
  justificationInvalidation: string;
  instantCreation: Date;


  constructor(id: number, dateNaissance: Date, actif: boolean, justificationInvalidation: string, instantCreation: Date) {
    this.id = id;
    this.dateNaissance = dateNaissance;
    this.actif = actif;
    this.justificationInvalidation = justificationInvalidation;
    this.instantCreation = instantCreation;
  }
}

export const useIndividuDonneePriveStore = defineStore('individuDonneePrive {\n', () => {
  const erreurSelection = ref<Erreur | null>(null);
  const individuDonneePriveConnecte = ref<IndividuDonneePrive | null>(null);

  async function rechercherById(idIndividu: number) {
    if (individuDonneePriveConnecte.value == null) {
      try {
        const response = await axiosInstance.get(`/private/individu/info-prive/${idIndividu}`);
        individuDonneePriveConnecte.value = response.data;
      } catch (error) {
        erreurSelection.value = new Erreur("Erreur lors de la récupération de l'individu")
      }
    }
    return individuDonneePriveConnecte
  }




  return {
    individuDonneePriveConnecte,
    rechercherById,
    erreurSelection,
  }
});